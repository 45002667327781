<template>
  <teleport to=".sc-modals-anchor">
    <div v-vb-is:modal.show ref="scModal" @vb-hidden-bs-modal="modalWasHidden"
         @vb-shown-bs-modal="modalWasShown"
         class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header row g-0">
            <div class="col-4">
              <NewSharePrevButton :showShareAgain="false">
                <template v-slot:prevButtonSelectFiles>
                  <button type="button" class="btn btn-link" data-bs-dismiss="modal" @click.prevent="">Close</button>
                </template>
              </NewSharePrevButton>
            </div>
            <div class="col-4">
              <h4 class="modal-title text-center">Send Tag</h4>
            </div>
            <div class="col-4 text-end">
              <span>
                <button class="btn btn-primary-orange fw-bold" type="button"
                        @click.prevent="send"
                        :disabled="!hasSelectedSomething">
                    <span>Send</span>
                  <ScIcon v-if="loadingState" name="spinnerFW" class="mx-3"/>
                </button>
            </span>
            </div>
          </div>
          <div class="modal-body pb-5 rounded-bottom">
            <div class="col-11">
              <h4>Recipients(s)</h4>
              <div class="mt-2 mb-3">
                <div>
                  <button v-for="(item, idx) in groupsSelected" :key="'mav-g-' + idx"
                          type="button" class="btn btn-outline-secondary me-2 mt-2">
                    <ScIcon name="users" class="me-2" v-sc-tooltip="'Users in this group'" />
                    <router-link v-if="mainMxUwCanPerform('a:list_users')"
                                 :to="{name: 'user-group-user-list', params: {workshopId: mainMxCurrentWorkshopId, groupId: item.id}}"
                                 style="white-space: break-spaces;">{{ item.showName }}</router-link>
                    <span v-else>{{ item.showName }}</span>
                    <span class="ms-2 p-0 m-0 text-muted" style="float:none; position:relative;"
                          v-sc-tooltip="'Remove'" @click.prevent="deselectGroup(item)">&times;</span>
                  </button>

                  <button v-for="(item, idx) in usersSelected" :key="'mav-u-' + idx"
                          type="button" class="btn btn-outline-secondary me-2 mt-2">
                              <span class="me-2" v-sc-tooltip="'User'">
                                <ScIcon v-if="item.roleCode === 'r:viewer'" name="userFW"/>
                                <ScIcon v-if="item.roleCode === 'r:editor'" name="userNinjaFW"/>
                                <ScIcon v-if="item.roleCode === 'r:manager'" name="userAstronautFW"/>
                                <ScIcon v-if="item.roleCode === 'r:reporter'" name="userBountyHunterFW"/>
                                <ScIcon v-if="item.roleCode === 'r:admin'" name="userCowboyFW"/>
                              </span>
                    <router-link v-if="mainMxUwCanPerform('a:list_users')"
                                 :to="{name: 'user-view', params: {workshopId: mainMxCurrentWorkshopId, userId: item.id}}"
                                 style="white-space: break-spaces;">{{ item.showName }}</router-link>
                    <span v-else style="white-space: break-spaces;">{{ item.showName }}</span>
                    <span class="ms-2 p-0 m-0 text-muted" style="float:none; position:relative;"
                          v-sc-tooltip="'Remove'" @click.prevent="deselectUser(item)">&times;</span>
                  </button>
                </div>
              </div>
            </div>

            <ScSelectWithSearchAndGroups :disabled="loadingState"
                                         :items="[groupsNotSelected, usersNotSelected]"
                                         :labels="['Groups', 'Users']"
                                         :emptyAfterSelection="true"
                                         placeholder="Select Groups or Users to send your tag to"
                                         @sc-select-item="selectGroupOrUser($event)" >
            </ScSelectWithSearchAndGroups>

            <div class="row text-muted">
              <div class="col mt-4">
                <ScIcon name="infoCircle" class="text-muted"/>
                Tag can only be sent to users that are in your group. To share a tag externally, share while presenting a tag and your recipient will see that sequence first.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import $ from 'jquery';
import _ from 'underscore'; // clone, findWhere

import ScIcon from "../common/ScIcon.vue";
import ViewerEmbedHandler from 'ScVueAliasViewerEmbedHandler';
import ScNotification from '../common/ScNotification.vue';
import ScSelectWithSearchAndGroups from "../../components/global/ScSelectWithSearchAndGroups.vue";
import MainAppMixin from "../../components/MainAppMixin";
import NewSharePrevButton from "../sharing/NewSharePrevButton.vue";

export default {
  name: "ViewerTagsShareModal",
  mixins: [MainAppMixin],
  components: {
    NewSharePrevButton,
    ScSelectWithSearchAndGroups,
    ScIcon,
  },
  props: {
    //
  },
  data () {
    return {
      selectedPageId: null,
      hasChanges: false,
      calcedNumResultDivsPerRow: 0,
      previewTagOnHiddenBool: false,

      users: [],
      groups: [],

      loadingState: false,
      loadedUserTagSharingStatus: false,
    }
  },
  mounted () {
    window.addEventListener('resize', this.calcNumItemsPerRow, false);
    this.getUserAndGroups();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.calcNumItemsPerRow, false);
  },
  methods: {
    send() {
      const ajaxUrl = ViewerEmbedHandler.ajaxWrapUrl('/main/user_presentation/ajax_share_existing_tag');
      this.loadingState = true;
      $.ajax({
        type: 'POST',
        url: ajaxUrl,
        data: {
          tag_id: this.$store.state.viewerTags.viewerTagShareId,
          workspace_id: this.options.workspaceId,
          presentation_id: this.scData.presentation.id,
          group_ids: this.selectedGroupIds.join(','),
          user_ids: this.selectedUserIds.join(',')
        },
        beforeSend: ViewerEmbedHandler.ajaxBeforeSend
      }).done((response)=> {
        if (response.status && response.status === 'ok') {
          if (response.message) {
            ScNotification.growlSuccessMsg(response.message);
          } else {
            ScNotification.growlSuccessMsg("Shared successfully.");
          }
        } else {
          ScNotification.growlErrMsg(response.message ?? 'Error occurred while sharing tag');
        }
        this.loadingState = false;
        this.hideModal();
      }).fail((response) => {
        ScNotification.growlErrMsg(response.responseJSON.message ?? 'Error occurred while sharing tag');
        this.loadingState = false;
      });
    },
    selectGroup (e) {
      if (!e.id) {
        return;
      }
      for (let group of this.groups) {
        if (group.id === e.id) {
          group.selected = true;
        }
      }
    },
    deselectGroup (e) {
      if (!e.id) {
        return;
      }
      for (let group of this.groups) {
        if (group.id === e.id) {
          group.selected = false;
        }
      }
    },
    selectUser (e) {
      if (!e.id) {
        return;
      }
      for (let user of this.users) {
        if (user.id === e.id) {
          user.selected = true;
        }
      }
    },
    deselectUser (e) {
      if (!e.id) {
        return;
      }
      for (let user of this.users) {
        if (user.id === e.id) {
          user.selected = false;
        }
      }
    },
    selectGroupOrUser(selectedItem) {
      if (!selectedItem) return;
      if (selectedItem.ugType === 'group') this.selectGroup(selectedItem);
      else this.selectUser(selectedItem);
    },
    _getDisplayName (user) {
      if (user.displayable_name === user.email) return user.email;
      return user.displayable_name + ' <' + user.email + '>';
    },
    getUserAndGroups() {
      const ajaxUrl = ViewerEmbedHandler.ajaxWrapUrl('/main/user_presentation/ajax_get_user_group_tag_sharing_list');
      $.ajax({
        type: "GET",
        url: ajaxUrl,
        data: {workspace_id: this.options.workspaceId, presentation_id: this.scData.presentation.id, pres_action: 'a:view_pres'},
      }).done((data) => {
        if (data.status === 'ok') {
          for (let user of data.users) {
            this.users.push({
              ugType: 'user',
              id: user.id,
              selected: false,
              showName: this._getDisplayName(user),
              roleCode: user.role_code
            });
          }

          this.groups.splice(0);
          let groups = Object.values(data.groups);
          for (let group of groups) {
            this.groups.push({
              ugType: 'group',
              id: group.id,
              selected: false,
              showName: group.name,
            });
          }
          return;
        }
        ScNotification.growlSuccessMsg("error retrieving user and group list");
      }).fail(() => {
        ScNotification.growlSuccessMsg("error retrieving user and group list");
      });
    },
    hideModal() {
      this.$refs.scModal.$vb.modal.hide();
    },
    modalWasShown() {
      this.calcNumItemsPerRow();
    },
    modalWasHidden() {
      this.$store.commit('viewerTagsShareTagId', null);
      this.$store.commit('vwShareTagsModalToggle', { shareTagsModalShown: false });
    },
    calcNumItemsPerRow() {
      setTimeout(() => {
        this.calcedNumResultDivsPerRow = 0;
        if (this.$refs.allResultsEl) {
          const grid = Array.from(this.$refs.allResultsEl.children);
          if (grid.length > 0) {
            const baseOffset = grid[0].offsetTop;
            const breakIndex = grid.findIndex(item => item.offsetTop > baseOffset);
            this.calcedNumResultDivsPerRow = (breakIndex === -1 ? grid.length : breakIndex);
          }
        }
      }, 1);
    },
  },
  computed: {
    hasSelectedSomething() {
      return this.usersSelected.length > 0 || this.groupsSelected.length > 0;
    },
    usersNotSelected() {
      return this.users.filter((u) => !u.selected);
    },
    groupsNotSelected() {
      return this.groups.filter((u) => !u.selected);
    },
    usersSelected: function() {
      return this.users.filter((u) => u.selected);
    },
    groupsSelected: function() {
      return this.groups.filter((ug) => ug.selected);
    },
    selectedUserIds() {
      return this.usersSelected.map(user => user.id)
    },
    selectedGroupIds() {
      return this.groupsSelected.map(group => group.id)
    },
    options() {
      return this.$store.state.vw.options;
    },
    viewerState() {
      return this.$store.state.vw.viewerState;
    },
    scData() {
      return this.$store.state.vw.scData;
    },
    currentTag () {
      return _.findWhere(this.$store.state.viewerTags.userTags,{ tag_uid: this.$store.state.viewerTags.viewerTagManageId });
    },
    tagName () {
      return (this.currentTag) ? "#" + this.currentTag.tag_name : '';
    },
  }
}
</script>
