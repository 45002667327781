
import _ from "underscore"; // throttle, findWhere, isNumber, where, sortBy, isEmpty
import ViewerSearch from "./ViewerSearch.vue";
import ViewerPinModal from "./ViewerPinModal.vue";
import ViewerEmbedHandler from 'ScVueAliasViewerEmbedHandler';
import ScCommonUtil from "../common/ScCommonUtil";
import ViewerResourceHandler from "./ViewerResourceHandler";
import ViewerShareUtil from "../sharing/ViewerShareUtil";
import ViewerUtil from "./ViewerUtil";


const MIN_SLIDE_VIEW_TIME_MS_BEFORE_RECORD_PAGE_VIEW = 500;

let _highlightScMainButtonsTimer = null;
let _screenSaverTimeout = null;
let _recordPageViewTimer = null;

export default {
    state: () => ({
        options: {
            staticRoot: 'https://cdn.showcaseworkshop.com',
            recordAnalytics: true,
            expired: false,
            shareEnabled: true,
            prefetchResources: true,   // precache resources
            enableOpeningScene: true,  // enable intro videos
            defaultScaleFactor: 1,
            kioskMode: false,
            likeEnabled: true,
            userTagsEnabled: false,
            showcaseFormsEnabled: true,
            baseZindexOffset: 10000000,  // 10 million
            openAtPageId: null,
            openAtAdHocPagelistPageIds: null,
            openAtResId: null,
            workspaceId: null,
            userId: null,
            userEmail: null,
            userDisplayName: null,
            workshopHeaderImg: null,
            webUrlUserJwt: null,
            sharedId: null,
            sharedKey: null
        },
        viewerState: {
            widgetsDimmed: true,
            widgetsShown: true,
            prevPageId: null,
            nextPageId: null,
            drawerPageId: null,
            drawerResourceId: null,
            drawerFullscreenShown: false,
            frameEleWidth: 100,
            frameEleHeight: 100,
            presScaledWidth: 100,
            presScaledHeight: 100,

            backHistory: [],
            pagelistId: null,
            pageId: null,
            adHocPagelist: {adHocPageIds: [], adHocTagUid: null, adHocName: null},
            resourceId: null,
            resourcePageId: null,  // the page the resource was opened from
            resourceHandler: null,
            resourceOpenedInternally: null,
            resourceIsOpeningVideo: false,
            resourceIsMovieLooping: false,
            isScreensaverVideoPlaying: false,
            searchId: null,
            presScaleFactor: 1,
            rootDivLeftMargin: 0,
            slideshowTransition: 'sc-trans-none',
            searchTransition: 'sc-trans-grow-stay',

            openOrShareResourceId: null,
            shareModalShown: false,
            addTagModalShown: false,
            manageTagsModalShown: false,
            shareTagsModalShown: false,
            pinModalForHotspotId: null,
            formsResourceId: null,
            transitionsEnabled: true,
            showVideoPlayer: false,
            isViewerOnline: true
        },
        scData: null
    }),

    getters: {
        vwRootPage: (state) => {
            let rootPage = null;
            if (state.scData && state.scData.presentationmetadata) {
                let rootPages = _.where(state.scData.page, {pagelist_id: state.scData.presentationmetadata.root_pagelist_id});
                if (!rootPages || rootPages.length === 0) return;
                rootPage = _.sortBy(rootPages, 'sort_order')[0];
            }
            return rootPage;
        },
    },

    actions: {

        vwFullscreen({ commit }) {
            ViewerEmbedHandler.fullscreenToggle();
            commit('vwDrawerHide');
        },

        vwToStart({ state, getters, dispatch, commit }) {
            if (state.viewerState.backHistory.length > 1) {  // we are deepter than the root slideshow
                dispatch('vwHome', {firstTimeHomeIsShown: false});
                if (state.options.recordAnalytics) {
                    ViewerEmbedHandler.recordUiAnalyticsEvent("viewer", "to-top");
                }
            } else if (state.viewerState.backHistory.length === 1 && getters.vwRootPage &&
                    state.viewerState.backHistory[0].pageId !== getters.vwRootPage.id) {  // we are in root slideshow but not on A1
                commit('vwSetPageInSamePagelist', {pageId: getters.vwRootPage.id});
            }
        },

        vwBack({ commit, state, dispatch }) {
            //console.log('vwBack', state.viewerState.backHistory.length);
            let viewerState = state.viewerState;
            if (viewerState.backHistory.length > 1) {
                let historyItem = viewerState.backHistory[viewerState.backHistory.length - 2];
                if (historyItem) {
                    if (historyItem.type === 'page') {
                        dispatch('vwPage', {pageId: historyItem.pageId, direction: 'stay', adHocPagelist: historyItem.adHocPagelist});
                    }
                    if (historyItem.type === 'resource') {
                        dispatch('vwResource', {pageId: historyItem.pageId, resourceId: historyItem.resourceId,
                            isOpeningVideo: historyItem.isOpeningVideo, isMovieLooping: historyItem.isMovieLooping,
                            isRecordingAnalytics: true, popHistory: true});
                    }
                    if (historyItem.type === 'search') {
                        dispatch('vwSearch', {searchId: historyItem.searchId, direction: 'stay'});
                    }
                }
            } else {
                commit('vwExit');
            }
        },

        vwHighlightScMainButtons({ state, commit }) {
            //console.log('highlightScMainButtons start timer', Math.random());
            if (state.viewerState && state.viewerState.widgetsDimmed) {
                commit('vwSetWidgetsDimmed', {widgetsDimmed: false});
            }
            if (_highlightScMainButtonsTimer) clearTimeout(_highlightScMainButtonsTimer);
            _highlightScMainButtonsTimer = setTimeout(() => {
                //console.log('highlightScMainButtons true', Math.random());
                if (state && state.scData && state.viewerState && !state.viewerState.widgetsDimmed) {  // we may not be running anymore so check
                    commit('vwSetWidgetsDimmed', {widgetsDimmed: true});
                }
            }, 3000);
        },

        vwRecordPageView({ state, dispatch }, { pageId }) {
            // we delay by X in case a user is quickly scrolling, this means we don't record if user spent less
            // than Xms on a slide
            if (_recordPageViewTimer) clearTimeout(_recordPageViewTimer);
            _recordPageViewTimer = setTimeout(() => {
                if (!state || !state.scData || !state.scData.presentation) return;
                dispatch('vwRecordScAnalytics', {alType: 'page_view',
                    alObj: { showcase_id: state.scData.presentation.id,
                        showcase_version: state.scData.presentation.version, page_id: pageId },
                    alWsId: state.options.workspaceId, alUsId: state.options.userId, alShId: state.options.sharedId});
            }, MIN_SLIDE_VIEW_TIME_MS_BEFORE_RECORD_PAGE_VIEW);

            if (state.options.prefetchResources) {
                // in the next event loop the preloading of resources for the next few pages
                setTimeout(() => { ViewerEmbedHandler.preloadNow(state.scData, pageId); });
            }
        },

        vwStartScreensaverTimeoutThrottled: _.throttle(({ state, dispatch }) => {

            if (!state.scData) return;

            let screensaverTimeoutSetting = state.scData.presentationmetadata.screensaver_timeout;
            let screensaverVideoResourceId = state.scData.presentationmetadata.screensaver_video_resource_id;

            //console.log('screensaver_video_resource_id', screensaverVideoResourceId, screensaverTimeoutSetting);
            if (!screensaverVideoResourceId) return;

            // if null, set to default 60 sec
            if (!screensaverTimeoutSetting) screensaverTimeoutSetting = 1;

            if (_screenSaverTimeout) clearTimeout(_screenSaverTimeout);

            if (state.viewerState.isScreensaverVideoPlaying && state.viewerState.resourceId === screensaverVideoResourceId) {
                //console.log('Stop screensaver');
                state.viewerState.isScreensaverVideoPlaying = false;
                dispatch('vwBack');
            }

            state.viewerState.isScreensaverVideoPlaying = false;

            _screenSaverTimeout = setTimeout(() => {
                if (state.options.kioskMode) {
                    dispatch('vwHome', {firstTimeHomeIsShown: false});
                }

                // don't do screensaver while a video is playing
                if (state.viewerState.resourceId) {
                    let resource = _.findWhere(state.scData.resource, {id: state.viewerState.resourceId});
                    if (resource && resource.content_type === 'movie') return;
                }

                // don't do screensaver while share, 'open or share', form, search, pin or tag dialogs active
                if (state.viewerState.openOrShareResourceId || state.viewerState.formsResourceId
                    || state.viewerState.shareModalShown || state.viewerState.pinModalForHotspotId
                    || state.viewerState.searchId
                    || state.viewerState.addTagModalShown || state.viewerState.manageTagsModalShown ) return;

                // don't show if tab is not active
                if (typeof document.hidden === 'boolean' && document.hidden) return;

                setTimeout(() => {
                    //console.log('Play screensaver', state.viewerState.pageId, screensaverVideoResourceId);
                    dispatch('vwResource', {pageId: state.viewerState.pageId, resourceId: screensaverVideoResourceId,
                        isOpeningVideo: false, isMovieLooping: true, isRecordingAnalytics: false, popHistory: false});
                    state.viewerState.isScreensaverVideoPlaying = true;

                    dispatch('vwRecordScAnalytics', {alType: 'screensaver_view', alObj: {
                            showcase_id: state.scData.presentation.id, showcase_version: state.scData.presentation.version,
                            page_id: state.viewerState.pageId, resource_id: state.viewerState.resourceId
                        }, alWsId: state.options.workspaceId, alUsId: state.options.userId, alShId: state.options.sharedId});

                });

            }, 1000 * (screensaverTimeoutSetting * 60));

        }, 1000),

        vwRecordScAnalytics({ state }, { alType, alObj, alWsId, alUsId, alShId }) {
            if (state.options.recordAnalytics) {
                ViewerEmbedHandler.recordScAnalyticsEvent(alType, alObj, alWsId, alUsId, alShId)
            }
        },

        vwLoadHotspotTarget({ state, dispatch, commit }, {hotspotId}) {
            let hotspot = _.findWhere(state.scData.hotspot, {id: hotspotId});
            if (!hotspot) return;

            let targetClickPinCodePassed = () => {
                //console.log('targetClickPinCodePassed');
                if (_.isNumber(hotspot.target_resource_id)) {
                    dispatch('vwResource', {pageId: hotspot.parent_page_id, resourceId: hotspot.target_resource_id,
                        isOpeningVideo: false, isMovieLooping: hotspot.is_movie_looping, isRecordingAnalytics: true,
                        popHistory: false});
                }
                if (_.isNumber(hotspot.target_page_id)) {
                    dispatch('vwPage', {pageId: hotspot.target_page_id, direction: 'up'});

                } else if (_.isNumber(hotspot.target_pagelist_id)) {
                    let targetPages = _.where(state.scData.page, {pagelist_id: hotspot.target_pagelist_id});
                    if (targetPages && targetPages.length > 0) {
                        let targetPage = _.sortBy(targetPages, 'sort_order')[0];
                        dispatch('vwPage', {pageId: targetPage.id, direction: 'up'});
                    }
                }
            };

            if (!_.isEmpty(hotspot.passcode) && !ViewerPinModal.isRememberedHotspot(hotspot.id)){  // passcode support
                commit('vwSetPinModalForHotspotId', { hotspotId: hotspot.id })
            }   else {
                targetClickPinCodePassed();
            }
        },

        vwHome({ state, commit, dispatch, getters }, { firstTimeHomeIsShown }) {
            //console.log('route home', 'loaded');
            let openAtPageIdOption = state.options.openAtPageId;

            let rootPages = _.where(state.scData.page, {pagelist_id: state.scData.presentationmetadata.root_pagelist_id});
            if ( ! rootPages || rootPages.length === 0 ) return;
            let rootPage = getters.vwRootPage;

            // check page exists
            if (openAtPageIdOption && !_.findWhere(state.scData.page, {id: openAtPageIdOption})) openAtPageIdOption = null;
            // validate openAtAdHocPagelistPageIds
            let adHocPagelistToLoad = null;
            if (openAtPageIdOption && state.options.openAtAdHocPagelistPageIds &&
                state.options.openAtAdHocPagelistPageIds.length > 0 &&
                state.options.openAtAdHocPagelistPageIds.includes(openAtPageIdOption)) {
                adHocPagelistToLoad = { adHocName: 'Custom Slideshow',
                    adHocPageIds: state.options.openAtAdHocPagelistPageIds, adHocTagUid: 'oa-' + ScCommonUtil.guid()};
            }
            if (!adHocPagelistToLoad && openAtPageIdOption === rootPage.id) openAtPageIdOption = null;

            let pageIdToLoad = null;
            if (firstTimeHomeIsShown && state.options.enableOpeningScene && !openAtPageIdOption
                && !state.options.openAtResId && state.scData.presentationmetadata.opening_scene_resource_id) {
                //console.log('show open video');
                commit('vwSaveBackHistory', {type: 'page', pageId: rootPage.id, adHocPagelist: null});  // when video done we go back to home
                dispatch('vwResource', {pageId: rootPage.id,
                    resourceId: state.scData.presentationmetadata.opening_scene_resource_id,
                    isOpeningVideo: true, isMovieLooping: false, isRecordingAnalytics: false, popHistory: false});

            } else if (openAtPageIdOption) {
                pageIdToLoad = openAtPageIdOption;

            } else {
                pageIdToLoad = rootPage.id;
            }
            if (state.options.openAtResId) {
                let pgId = ViewerUtil.guessPageIdForResource(state.scData, state.options.openAtResId, rootPage.id);
                dispatch('vwResource', {pageId: pgId, resourceId: state.options.openAtResId,
                    isOpeningVideo: false, isMovieLooping: false, isRecordingAnalytics: true, popHistory: false});
                if (!state.viewerState.resourceOpenedInternally) {
                    commit('vwExit');  // if showcase needed to open a file externally (global search result) then exit
                }

            }   else if (pageIdToLoad) {
                commit('vwClearBackHistory');
                commit('vwSaveBackHistory', {type: 'page', pageId: pageIdToLoad, adHocPagelist: adHocPagelistToLoad});
                commit('vwSetSlideshowTransition', { slideshowTransition:
                        firstTimeHomeIsShown ? 'sc-trans-none' : 'sc-trans-stay-shrink' });

                if (state.viewerState.resourceId) commit('vwResourceHideNow');

                setTimeout(() => {
                    commit('vwPageHideNow');
                });
                setTimeout(() => {
                    commit('vwDrawPage', {pageId: pageIdToLoad, adHocPagelist: adHocPagelistToLoad});
                    dispatch('vwHighlightScMainButtons');
                });
            }
            commit('vwDrawerHide');
        },

        vwPage({ state, dispatch, commit }, { pageId, direction, adHocPagelist }) {
            //console.log('vwPage', pageId, direction, adHocPagelist);
            if ('stay' === direction) {
                commit('vwSaveBackHistory', null);
                commit('vwSetSlideshowTransition', { slideshowTransition: 'sc-trans-stay-shrink' });
                commit('vwSetSearchTransition', { searchTransition: 'sc-trans-stay-shrink' });
            }
            if ('up' === direction) {
                commit('vwSaveBackHistory', {type: 'page', pageId: Number(pageId), adHocPagelist: adHocPagelist});
                commit('vwSetSlideshowTransition', { slideshowTransition: 'sc-trans-grow-stay' });
                commit('vwSetSearchTransition', { searchTransition: 'sc-trans-grow-stay' });
            }
            setTimeout(() => {
                if (state.viewerState.searchId) commit('vwSearchHideNow');
                if (state.viewerState.resourceId) commit('vwResourceHideNow');
                setTimeout(() => {
                    commit('vwDrawPage', {pageId: pageId, adHocPagelist: adHocPagelist});
                    dispatch('vwHighlightScMainButtons');
                });
                commit('vwDrawerHide');
            });
        },

        vwResource({ state, dispatch, commit }, { pageId, resourceId, isOpeningVideo, isMovieLooping,
                isRecordingAnalytics, popHistory }) {
            //console.log('route resource', pageId, resourceId, isOpeningVideo, isMovieLooping, isRecordingAnalytics, popHistory);

            let resource = _.findWhere(state.scData.resource, {id: resourceId});
            if (!resource) {
                return;
            }

            let resourceOpenedInternally = null;
            let resourceHandler = ViewerResourceHandler.determineHandler({ dispatch, commit }, state.options, state.viewerState,
                resource, state.scData);

            if (!resourceHandler) {
                //console.log('no resource handler found for resourceId', resourceId);
                resourceOpenedInternally = null;
            } else {
                resourceOpenedInternally = resourceHandler.isOpenedInternally ?
                    resourceHandler.isOpenedInternally() : false;
                //console.log('resourceHandler', resourceHandler, openHandledInternally);
            }

            if (resourceOpenedInternally) {

                commit('vwDrawResource', { pageId: pageId, resourceId: resourceId,
                    resourceOpenedInternally: resourceOpenedInternally, isOpeningVideo: isOpeningVideo,
                    isMovieLooping: isMovieLooping });

                if (popHistory) {
                    commit('vwSaveBackHistory', null);
                }   else {
                    commit('vwSaveBackHistory', {
                        type: 'resource',
                        pageId: Number(pageId),
                        resourceId: Number(resourceId),
                        isOpeningVideo: isOpeningVideo, isMovieLooping: isMovieLooping
                    });
                }
                commit('vwSetSlideshowTransition', { slideshowTransition: 'sc-trans-grow-stay' });
                commit('vwSetSearchTransition', { searchTransition: 'sc-trans-grow-stay' });

                setTimeout(() => {  // must be done next tick to ensure transition set correctly
                    commit('vwPageHideNow');
                    commit('vwSearchHideNow');
                });

            } else {
                //console.log('resourceOpenedInternally false, handle using resource handler directly');
                resourceHandler.displayResource();
                setTimeout(() => {
                    if (!state.scData) return;  // we may have been unloaded
                    dispatch('vwHighlightScMainButtons');
                });
            }
            if (isRecordingAnalytics) {
                dispatch('vwRecordScAnalytics', {alType: 'resource_view',
                    alObj: { showcase_id: state.scData.presentation.id,
                        showcase_version: state.scData.presentation.version, page_id: pageId, resource_id: resourceId },
                    alWsId: state.options.workspaceId, alUsId: state.options.userId, alShId: state.options.sharedId});
            }
            commit('vwDrawerHide');
        },

        vwSearch({ state, commit }, { searchId, direction }) {
            //console.log('route search', searchId, direction);
            if (Number(searchId) === 0) searchId = ViewerSearch.getNextSearchId();

            if (direction === 'up') {
                commit('vwSetSearchTransition', { searchTransition: 'sc-trans-grow-stay' });
                commit('vwSetSlideshowTransition', { slideshowTransition: 'sc-trans-grow-stay' });
                commit('vwSaveBackHistory', { type: 'search', searchId: Number(searchId) });
            }
            if (direction === 'stay') {
                commit('vwSetSearchTransition', { searchTransition: 'sc-trans-stay-shrink' });
                commit('vwSetSlideshowTransition', { slideshowTransition: 'sc-trans-stay-shrink' });
                commit('vwSaveBackHistory', null);
            }
            setTimeout(() => {
                commit('vwDrawSearch', {searchId: searchId});

                if (state.options.recordAnalytics) {
                    ViewerEmbedHandler.recordUiAnalyticsScreenView("search");
                }

                commit('vwDrawerHide');
                setTimeout(() => {
                    commit('vwPageHideNow');
                });
                commit('vwResourceHideNow');
            });
        },

    },

    mutations: {

        vwSetOptions(state, { userOptions, viewerStateDefaults, scData }) {
            Object.assign(state.options, userOptions);
            Object.assign(state.viewerState, viewerStateDefaults);
            state.scData = scData;
        },

        vwSetKioskMode(state, { kioskMode }) {
            state.options.kioskMode = kioskMode;
        },

        vwWidgetsToggle(state, { widgetsShown }) {
            state.viewerState.widgetsShown = widgetsShown;
        },

        vwAddTagModalToggle(state, { addTagModalShown }) {
            state.viewerState.addTagModalShown = addTagModalShown;
        },

        vwManageTagsModalToggle(state, { manageTagsModalShown }) {
            state.viewerState.manageTagsModalShown = manageTagsModalShown;
        },

        vwShareTagsModalToggle(state, { shareTagsModalShown }) {
            state.viewerState.shareTagsModalShown = shareTagsModalShown;
        },

        /// navigation

        vwExit(state) {
            //console.log('vwExit');
            if (_screenSaverTimeout) clearTimeout(_screenSaverTimeout);
            if (_highlightScMainButtonsTimer) clearTimeout(_highlightScMainButtonsTimer);

            state.viewerState.drawerPageId = null;
            state.viewerState.drawerResourceId = null;

            if (state.viewerState.drawerFullscreenShown) ViewerEmbedHandler.fullscreenExit();
            ScCommonUtil.triggerEvent('scapp.exitViewer');
        },

        vwPrev(state) {
            state.viewerState.drawerPageId = null;
            state.viewerState.drawerResourceId = null;
            if (state.viewerState.prevPageId) state.viewerState.pageId = state.viewerState.prevPageId;
        },

        vwNext(state) {
            state.viewerState.drawerPageId = null;
            state.viewerState.drawerResourceId = null;
            if (state.viewerState.nextPageId) state.viewerState.pageId = state.viewerState.nextPageId;
        },

        vwSetPageInSamePagelist(state, { pageId }) {
            state.viewerState.pageId = pageId;
            if (state.viewerState.drawerPageId) state.viewerState.drawerPageId = pageId;
            state.viewerState.backHistory[state.viewerState.backHistory.length-1].pageId = pageId;
        },

        vwSetNextPrevPages(state, { nextPageId, prevPageId }) {
            state.viewerState.nextPageId = nextPageId;
            state.viewerState.prevPageId = prevPageId;
        },

        vwDrawerShow(state, { drawerPageId, drawerResourceId }) {
            //console.log('drawerShow', drawerPageId, drawerResourceId);
            state.viewerState.drawerPageId = drawerPageId;
            state.viewerState.drawerResourceId = drawerResourceId;
            if (state.options.recordAnalytics) {
                ViewerEmbedHandler.recordUiAnalyticsEvent("viewer", "tray-open");
            }
        },

        vwDrawerHide(state) {
            //console.log('drawerHide');
            state.viewerState.drawerPageId = null;
            state.viewerState.drawerResourceId = null;
        },

        vwShareNow(state) {
            //console.log('shareNow', viewerState.backHistory, scData.presentationmetadata);
            let currentShareResource = _.findWhere(state.scData.resource, {id: state.viewerState.resourceId});

            // if this file is specifically enabled for sharing we allow 'open with'
            let resIsListedOnPageOrAll = ViewerShareUtil.isResListedOnPageOrAllImpl(state.scData,
              state.viewerState.resourcePageId, state.viewerState.resourceId);

            if (resIsListedOnPageOrAll && currentShareResource && currentShareResource.suffix === 'pdf'
                && state.viewerState.openOrShareResourceId === null) {
                state.viewerState.openOrShareResourceId = currentShareResource.id;

            } else {
                state.viewerState.openOrShareResourceId = null;
                state.viewerState.shareModalShown = true;
            }
            state.viewerState.drawerPageId = null;
            state.viewerState.drawerResourceId = null;
        },

        vwSetWidgetsDimmed(state, {widgetsDimmed}) {
            state.viewerState.widgetsDimmed = widgetsDimmed;
        },

        vwDrawResource(state, { pageId, resourceId, isOpeningVideo, isMovieLooping, resourceOpenedInternally }) {
            //console.log('vwDrawResource');
            state.viewerState.resourceOpenedInternally = resourceOpenedInternally;
            state.viewerState.resourceIsOpeningVideo = isOpeningVideo;
            state.viewerState.resourceIsMovieLooping = isMovieLooping;
            state.viewerState.resourceId = resourceId;
            state.viewerState.resourcePageId = pageId;
        },

        vwDrawPage(state, { pageId, adHocPagelist }) {

            //console.log('drawPage', pageId, adHocPagelist);
            let page = _.findWhere(state.scData.page, {id: pageId});
            if (!page) {
                //console.debug('no page found for pageId', pageId);
                return;
            }
            state.viewerState.pageId = pageId;
            state.viewerState.pagelistId = page.pagelist_id;
            state.viewerState.adHocPagelist.adHocPageIds.splice(0);
            state.viewerState.adHocPagelist.adHocTagUid = null;
            state.viewerState.adHocPagelist.adHocName = null;

            if (adHocPagelist && adHocPagelist.adHocPageIds.length > 0) {
                let pageIdInAdhoc = adHocPagelist.adHocPageIds.includes(pageId);
                state.viewerState.pageId = pageIdInAdhoc ? pageId : adHocPagelist.adHocPageIds[0];
                state.viewerState.pagelistId = null;
                state.viewerState.adHocPagelist.adHocPageIds.push(...adHocPagelist.adHocPageIds);
                state.viewerState.adHocPagelist.adHocTagUid = adHocPagelist.adHocTagUid;
                state.viewerState.adHocPagelist.adHocName = adHocPagelist.adHocName;
            }
        },

        vwDrawSearch(state, { searchId }) {
            state.viewerState.searchId = searchId;
        },

        vwSetPinModalForHotspotId(state, { hotspotId }) {
            state.viewerState.pinModalForHotspotId = hotspotId;
        },

        vwPageHideNow(state) {
            //console.log('pageHideNow', viewerState.slideshowTransition);
            state.viewerState.pagelistId = null;
            state.viewerState.pageId = null;
            state.viewerState.adHocPagelist.adHocPageIds.splice(0);
            state.viewerState.adHocPagelist.adHocTagUid = null;
            state.viewerState.adHocPagelist.adHocName = null;
        },

        vwResourceHideNow(state) {
            state.viewerState.resourceId = null;
            state.viewerState.resourcePageId = null;
            state.viewerState.openOrShareResourceId = null;
        },

        vwSearchHideNow(state) {
            state.viewerState.searchId = null;
        },

        vwShareHideNow(state) {
            state.viewerState.shareModalShown = false;
        },

        vwSetSlideshowTransition(state, { slideshowTransition }) {
            state.viewerState.slideshowTransition = slideshowTransition;
        },

        vwSetSearchTransition(state, { searchTransition }) {
            state.viewerState.searchTransition = searchTransition;
        },

        vwClearBackHistory(state) {
            state.viewerState.backHistory.splice(0);
        },

        vwSaveBackHistory(state, historyObj) {
            if (historyObj) state.viewerState.backHistory.push(historyObj)
            else state.viewerState.backHistory.pop()
        },
        
        vwSetOnline(state, { isViewerOnline }) {
            state.viewerState.isViewerOnline = isViewerOnline;
        },

        vwSetDimensions(state, { frameEleWidth, frameEleHeight, presScaledWidth, presScaledHeight, presScaleFactor, rootDivLeftMargin }) {
            state.viewerState.frameEleWidth = frameEleWidth;
            state.viewerState.frameEleHeight = frameEleHeight;
            state.viewerState.presScaledWidth = presScaledWidth;
            state.viewerState.presScaledHeight = presScaledHeight;
            state.viewerState.presScaleFactor = presScaleFactor;
            state.viewerState.rootDivLeftMargin = rootDivLeftMargin;
        },

        vwSetFormsResourceId(state, { formsResourceId }) {
            //console.log('vwSetFormsResourceId', formsResourceId);
            state.viewerState.formsResourceId = formsResourceId;
        },

        vwSetOpenOrShareResourceId(state, { openOrShareResourceId }) {
            state.viewerState.openOrShareResourceId = openOrShareResourceId;
        },

        vwDestroy(state) {
            state.scData = null;  // free up memory
        }


    }

};
