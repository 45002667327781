
import ViewerEmbedHandler from 'ScVueAliasViewerEmbedHandler';
import ScNativeExts from "ScVueAliasScNativeExts";
import $ from "jquery";
import _ from 'underscore'; // findWhere
import ScNotification from "../common/ScNotification.vue";
import ScCommonUtil from "../common/ScCommonUtil";

let _state = {

    workspaceId: null,
    presentationId: null,

    userTags: [],
    viewerTagSelectedId: null,
    viewerTagManageId: null,
    viewerTagShareId: null,
    viewerTagsUserTagsLoading: false

};



let _getters = {



};


let _actions = {

    viewerTagsLoadUserTags(context, opts) {
        context.commit('viewerTagsLoadUserTagsLoadingUpdate', true);

        ViewerEmbedHandler.loadViewerTagsOfflineJson(opts.presentationId).then((loadedJson) => {
            if (loadedJson) context.commit('viewerTagsUserTagsLoad', loadedJson);

            if (!ScNativeExts.isOnline()) return;

            $.ajax({
                method: 'GET', url: ViewerEmbedHandler.ajaxWrapUrl('/main/user_presentation/ajax_get_pres_tags'),
                data: {
                    workspace_id: opts.workspaceId, presentation_id: opts.presentationId
                }, beforeSend: ViewerEmbedHandler.ajaxBeforeSend
            }).done((data) => {
                context.commit('viewerTagsUserTagsUpdate', {data: data, pages: opts.pages});
                ViewerEmbedHandler.saveViewerTagsOfflineJson(opts.presentationId, {userTags: context.state.userTags});
            }).fail((jqXhr) => {
                ScNotification.growlXhrError(jqXhr, 'loading user tags');
            }).always(() => {
                context.commit('viewerTagsLoadUserTagsLoadingUpdate', false);
            });
        });

    },

    viewerTagsSaveUserTags(context, opts) {
        //console.log(context.state.userTags)
        $.ajax({
            method: 'POST', url: ViewerEmbedHandler.ajaxWrapUrl('/main/user_presentation/ajax_save_pres_tags'),
            data: {
                workspace_id: opts.workspaceId, presentation_id: opts.presentationId,
                tags_json: JSON.stringify(context.state.userTags)
            }, beforeSend: ViewerEmbedHandler.ajaxBeforeSend
        }).done(() => {
            ViewerEmbedHandler.saveViewerTagsOfflineJson(opts.presentationId, {userTags: context.state.userTags});
        }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'saving user tags');
        }).always(() => {
            //
        });
    },

    viewerTagsRenameTag: (context, opts) => {
        return new Promise((resolve) => {
            context.commit('viewerTagsRenameTagUpdate', opts.value);
            context.dispatch('viewerTagsSaveUserTags', opts);
            resolve();
        });
    },


    viewerTagsAddNewUserTag: (context, opts) => {
        return new Promise((resolve) => {
            context.commit('viewerTagsAddNewUserTagUpdate', opts.value);
            context.dispatch('viewerTagsSaveUserTags', opts);
            resolve();
        });
    },

    viewerTagsToggleUserTag: (context, opts) => {
        return new Promise((resolve) => {
            context.commit('viewerTagsToggleUserTagUpdate', opts.value);
            context.dispatch('viewerTagsSaveUserTags', opts);
            resolve();
        });
    },
    viewerTagsChangeSortOrder: (context, opts) => {
        return new Promise((resolve) => {
            context.commit('viewerTagsChangeSortOrderUpdate', opts.value);
            context.dispatch('viewerTagsSaveUserTags', opts);
            resolve();
        });
    },
    viewerTagsDeleteTag: (context, opts) => {
        return new Promise((resolve) => {
            context.commit('viewerTagsDeleteTagUpdate', opts.value);
            context.dispatch('viewerTagsSaveUserTags', opts);
            context.commit('viewerTagsSelectedTagIdUpdate', null);
            resolve();
        });
    },


};


let _mutations = {
    //update

    viewerTagsUserTagsLoad: (state, loadedJson) => {
        if (loadedJson && loadedJson.userTags) {
            state.userTags.splice(0);
            state.userTags.push(...loadedJson.userTags);
        }
    },
    viewerTagsUserTagsUpdate: (state, value) => {
        //console.log('viewerTagsUserTagsUpdate', value);
        state.userTags.splice(0);

        if (value.data.tags && value.data.tags.length === 0) return;
        // make sure tagged_pages not include deleted / orphaned pages
        for (let tag of value.data.tags) {
            let existing_tagged_pages = [];
            for (let page of tag.tagged_pages) {
                let pageExists = _.findWhere(value.pages, { id: Number(page) });
                if (pageExists) {
                    existing_tagged_pages.push(page);
                }
            }
            tag.tagged_pages.splice(0);
            tag.tagged_pages.push(...existing_tagged_pages);
        }

        state.userTags.push(...value.data.tags);
    },

    viewerTagsAddNewUserTagUpdate: (state, value) => {
        state.userTags.push({"tag_uid": ScCommonUtil.guid(), "tag_name": value.tag_name, "tagged_pages": value.tagged_pages})
    },

    viewerTagsToggleUserTagUpdate: (state, value) => {
        let tag = _.findWhere(state.userTags, {tag_uid: value.tag_uid});
        tag.tagged_pages.splice(0);
        tag.tagged_pages = value.tagged_pages;
    },

    viewerTagsChangeSortOrderUpdate: (state, value) => {
        if (!value.tag_uid || !value.tagged_pages) return;
        let tag =  _.findWhere(state.userTags, {tag_uid: value.tag_uid});
        tag.tagged_pages.splice(0);
        tag.tagged_pages = value.tagged_pages;
    },

    viewerTagsRenameTagUpdate: (state, value) => {
        let tag = _.findWhere(state.userTags, {tag_uid: value.tag_uid});
        tag.tag_name = value.tag_name;
    },

    viewerTagsDeleteTagUpdate: (state, value) => {
        let filteredTags = state.userTags.filter(tag => {
            return tag.tag_uid !== value.tag_uid;
        });
        state.userTags.splice(0);
        state.userTags = filteredTags;

    },

    viewerTagsStoreReset: (state) => {
        state.workspaceId = null;
        state.presentationId = null;
        state.userTags.splice(0);
        state.viewerTagSelectedId = null;
        state.viewerTagManageId = null;
    },

    viewerTagsLoadUserTagsLoadingUpdate: (state, value) => state.viewerTagsUserTagsLoading = value,
    viewerTagsSelectedTagIdUpdate: (state, value) => state.viewerTagSelectedId = value,
    viewerTagsManageTagIdUpdate: (state, value) =>  state.viewerTagManageId = value,
    viewerTagsShareTagId: (state, value) =>  state.viewerTagShareId = value,

};

export default {
    state: _state,
    getters: _getters,
    actions: _actions,
    mutations: _mutations
};
