<template>
    <div class="scRootDiv d-block" v-if="viewerState && options">

        <div v-if="options.expired" class="scExpiredWatermark" :style="{
                backgroundImage: 'url(\''+options.staticRoot +'/showcase-icons/5.1.0/expired_watermark.png\')',
                width: '512px',
                height: '384px',
                left: ((viewerState.frameEleWidth-(512))/2) +'px',
                top: ((viewerState.frameEleHeight-384)/2) + 'px'
            }"></div>

        <ViewerWidgets></ViewerWidgets>

        <div class="scPresDiv" :style="{
                    width: '100%',
                    height: '100%'
                    }">

            <ViewerSearch
                    v-if="viewerState.searchId"
                    :key="'s-'+viewerState.searchId"></ViewerSearch>

            <ViewerResource
                    v-if="viewerState.resourceId && viewerState.resourceOpenedInternally"
                    :key="'r-'+viewerState.resourceId"
                    :pauseAllVideos="pauseAllVideos"></ViewerResource>

            <transition :name="viewerState.transitionsEnabled ? viewerState.slideshowTransition: 'sc-trans-none'">
                <ViewerSlideshow
                        v-if="viewerState.pagelistId || viewerState.adHocPagelist.adHocTagUid"
                        :pagelistId="viewerState.pagelistId"
                        :pageId="viewerState.pageId"
                        :key="'ss-' + String(viewerState.pagelistId) + '-' + String(viewerState.adHocPagelist.adHocTagUid)"
                        :pauseAllVideos="pauseAllVideos"
                        :suppressAnalyticsEvents="suppressAnalyticsEvents"></ViewerSlideshow>
            </transition>

        </div>

        <ViewerPinModal v-if="viewerState.pinModalForHotspotId"
            :key="'hp-'+viewerState.pinModalForHotspotId"></ViewerPinModal>

        <ViewerShare v-if="viewerState.shareModalShown"></ViewerShare>

        <ViewerTagsAddModal v-if="viewerState.addTagModalShown"></ViewerTagsAddModal>

        <ViewerTagsManageModal v-if="viewerState.manageTagsModalShown"></ViewerTagsManageModal>
        <ViewerTagsShareModal v-if="viewerState.shareTagsModalShown"></ViewerTagsShareModal>

        <ViewerOpenOrShareModal v-if="viewerState.openOrShareResourceId"
            :key="'vos-'+viewerState.openOrShareResourceId"></ViewerOpenOrShareModal>

        <ViewerForms v-if="viewerState.formsResourceId"
            :key="'vf-'+viewerState.formsResourceId"></ViewerForms>

    </div>
</template>



<script>

    import _ from 'underscore';  // debounce
    import ViewerSlideshow from './ViewerSlideshow.vue';
    import ViewerResource from './ViewerResource.vue';
    import ViewerWidgets from './ViewerWidgets.vue';
    import ViewerPinModal from './ViewerPinModal.vue'
    import ViewerSearch from './ViewerSearch.vue'
    import ViewerOpenOrShareModal from './ViewerOpenOrShareModal.vue'
    import ViewerForms from './ViewerForms.vue'
    import ViewerShare from './ViewerShare.vue'
    import ScNativeExts from 'ScVueAliasScNativeExts';
    import ViewerEmbedHandler from 'ScVueAliasViewerEmbedHandler';
    import ViewerTagsAddModal from "./ViewerTagsAddModal.vue";
    import ViewerTagsManageModal from "./ViewerTagsManageModal.vue";
    import ViewerTagsShareModal from "./ViewerTagsShareModal.vue";


    export default {
        name: 'ViewerRoot',
        components: {
          ViewerTagsShareModal, ViewerSlideshow, ViewerWidgets, ViewerResource, ViewerSearch, ViewerOpenOrShareModal,
            ViewerForms, ViewerShare, ViewerPinModal, ViewerTagsAddModal, ViewerTagsManageModal},
        props: {
            pauseAllVideos: {type: Boolean, default: false},
            suppressAnalyticsEvents: {type: Boolean, default: false},
        },
        data() {
            return {
                screenSaverTimeout: null,
                onlineDetectInterval: null,
            }
        },
        async mounted() {
            // allow loading from another vue component (ViewerRoot.attach() is deprecated)

            ViewerPinModal.clearRealms();
            window.addEventListener('touchstart', this.movementDetected, {passive: true});
            window.addEventListener('click', this.movementDetected, {passive: true});
            window.addEventListener('keydown', this.movementDetected, {passive: true});
            window.addEventListener('mousemove', this.movementDetected, {passive: true});
            window.addEventListener('resize', this.windowResized, {passive: true});
            window.addEventListener('fullscreenchange', this.windowResized, {passive: true});
            if (ViewerEmbedHandler.onAppStart) ViewerEmbedHandler.onAppStart(this.$store);

            this.calcScale();
            // open fullscreen if last presentation was opened in fullscreen
            if (await ViewerEmbedHandler.fullscreenPossible() && ViewerEmbedHandler.lastShowcaseInFullscreen()) {
                ViewerEmbedHandler.fullscreenToggle();
            }

            this.movementDetected();

            this.onlineDetectInterval = setInterval(() => {
                let isOnline = ScNativeExts.isOnline();
                if (isOnline !== this.viewerState.isViewerOnline) {
                    this.$store.commit('vwSetOnline', {isViewerOnline: isOnline });
                }
                //console.log('this.viewerState.isViewerOnline', this.viewerState.isViewerOnline);
            }, 5000);
        },
        beforeUnmount() {
            if (ViewerEmbedHandler.onAppDestroy) ViewerEmbedHandler.onAppDestroy();
            if (this.onlineDetectInterval) clearInterval(this.onlineDetectInterval);
            window.removeEventListener('touchstart', this.movementDetected, {passive: true});
            window.removeEventListener('click', this.movementDetected, {passive: true});
            window.removeEventListener('keydown', this.movementDetected, {passive: true});
            window.removeEventListener('mousemove', this.movementDetected, {passive: true});
            window.removeEventListener('resize', this.windowResized, {passive: true});
            window.removeEventListener('fullscreenchange', this.windowResized, {passive: true});
            //exit fullscreen on leaving viewer
            if (ViewerEmbedHandler.lastShowcaseInFullscreen()) {
                ViewerEmbedHandler.fullscreenExit();
            }
        },
        methods: {
            windowResized: _.debounce(function() { // when the window changes size, resize us
                //console.log('windowResized');
                this.calcScale();
            }, 250),  // delay slightly so we don't kill the browser

            calcScale() {
                //console.log('calcScale')
                let presentation = this.scData.presentation;
                let frameEleWidth = document.documentElement.clientWidth;
                let frameEleHeight = document.documentElement.clientHeight;

                let smallScreen = frameEleWidth < 768;
                let presScaleFactor = Math.min(frameEleWidth / presentation.width,
                    smallScreen ? 1.5 : (frameEleHeight / presentation.height),
                    1.5);  // don't overscale too far

                // need to scale us down as we do a fit to height of the entire presentation
                let presScaledWidth = presScaleFactor * presentation.width;
                let presScaledHeight = presScaleFactor * presentation.height;
                //console.log('resize frame to ', presScaledWidth, presScaledHeight);

                let rootDivLeftMargin = Math.max((frameEleWidth - presScaledWidth)/2, 0);
                //console.log('viewport', frameEleWidth, frameEleHeight, 'scaled', presScaleFactor, presScaledWidth);

                this.$store.commit('vwSetDimensions', { frameEleWidth, frameEleHeight, presScaledWidth, presScaledHeight, presScaleFactor, rootDivLeftMargin })
            },

            movementDetected: _.debounce(function() {
              this.commitMovementDetected();
            }, 250),

            commitMovementDetected() {
                if (this.$store.state && this.$store.state.vw) {
                  this.$store.dispatch('vwHighlightScMainButtons');
                  this.$store.dispatch('vwStartScreensaverTimeoutThrottled');
                }
            },

        },

        computed: {
            options() {
                return this.$store.state.vw.options;
            },
            viewerState() {
                return this.$store.state.vw.viewerState;
            },
            scData() {
                return this.$store.state.vw.scData;
            },
        }

    }

</script>
